import React from 'react'
import { Button, Form, FormGroup, Input } from 'reactstrap'
import { connect } from 'react-redux'



const Cform = ({ lang }) => (
    <Form style={{ maxWidth: '600px', margin: 'auto', marginBottom: '100px' }} name="Contact Form" method="POST" data-netlify="true" className='mt-3'>
        <input type="hidden" name="form-name" value="Contact Form" />
        <FormGroup>
            <Input type="text" name="first" id="first" placeholder={lang === 'english' ? 'First Name (required)' : 'Nombre (required)'} required />
        </FormGroup>
        <FormGroup>
            <Input type="text" name="last" id="last" placeholder={lang === 'english' ? 'Last Name (required)' : 'Apellido (required)'} required />
        </FormGroup>
        <FormGroup>
            <Input type="email" name="email" id="email" placeholder={lang === 'english' ? 'Email (optional)' : 'Correo electrónico (opcional)'} />
        </FormGroup>
        <FormGroup>
            <Input type="textarea" name="message" id="message" placeholder={lang === 'english' ? 'Message (required)' : 'Mensaje (necesario)'} required />
        </FormGroup>
        <Button type='submit'>Submit</Button>
    </Form>
)


export default connect(state => ({
    lang: state.lang
}), null)(Cform)


