import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from '../components/contact-form'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import styles from '../styles/layout.module.css'


const Contact = ({ lang }) => {

  return (
    <Layout>
      <SEO title="Contact" />
      <Container className='mt-4'>
        <Row>
          <Col className={styles.main} style={{ textAlign: 'center' }}>
            {lang === 'english' ? <h1>Contact</h1> : <h1>Contacto</h1>}
            <Form />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect(state => ({
  lang: state.lang
}), null)(Contact)


//path={location.pathname}
  // const location = useLocation()